import React from 'react';

export const Context = React.createContext(null);

export class Provider extends React.PureComponent {
	constructor(props) {
		super(props);

		const {store} = props;

		this.state = {
			state: store.getState(),
			store
		};
	}

	componentDidMount = () => {
		this.props.store.listen(this.updateState);
	}

	componentWillUnmount = () => {
		this.props.store.unlisten(this.updateState);
	}

	updateState = () => {
		this.setState({
			state: this.props.store.getState(),
		});
	}

	render = () => (
		<Context.Provider value={this.state}>
			{this.props.children}
		</Context.Provider>
	);
}

export default Provider;

export const Consumer = Context.Consumer;

export const connectToStore = (mapStateToProps, mapStoreToProps) => Component => props => {
	return (
		<Consumer>{({
			store,
			state
		}) => (
			<Component
				{...(mapStateToProps ? mapStateToProps(state, props) : {})}
				{...(mapStoreToProps ? mapStoreToProps(store, props) : {})}
				{...props}
			/>
		)}</Consumer>
	);
};