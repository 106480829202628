const React = require('react');
const Layout = require('./src/components/Layout').default;
const Provider = require('./src/components/Provider').default;
const createStore = require('./src/stores/AppStore').default;
const app = require('magic-tricks/lib/getApp').default();

// Create store
app.store = createStore();

exports.onRouteUpdate = ({location, previousLocation}) => {
	// TODO: send GA updates
};

// Wrap every page with the main layout
exports.wrapPageElement = ({element, props}) => (
	<Layout {...props}>{element}</Layout>
);

// Wrap application with provider
exports.wrapRootElement = ({element, props}) => (
	<Provider
		{...props}
		store={app.store}>
		{element}
	</Provider>
);

// Site Credit
console.log(`
Site Credit
===========
Development : https://gardener.nyc
`);