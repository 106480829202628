import createStore from 'magic-tricks/lib/createStore';

export const DEFAULT_STATE = {
	isTakeoverOpen: false,
	isDark: false,
};

//
// === Actions ===
//

const openTakeover = store => () => {
	store.setState({
		isTakeoverOpen: true,
	});
};

const closeTakeover = store => () => {
	store.setState({
		isTakeoverOpen: false,
	});
};

//
// === Create Store ===
//

export default (initialState = {}) => {
	//
	// === Store ===
	//
	
	const store = createStore({
		...DEFAULT_STATE,
		...initialState,
	});

	//
	// === Interface ===
	//
	
	return {
		// Interface
		getState: store.getState,
		setState: store.setState,
		listen: store.listen,
		unlisten: store.unlisten,

		// Actions
		openTakeover: openTakeover(store),
		closeTakeover: closeTakeover(store),
	};
};