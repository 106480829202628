import '../styles/main.scss';

import React from 'react';
import Helmet from 'react-helmet';
import {connectToStore} from './Provider';


const Layout = ({
	children,
	pageContext,
	isScrollDisabled,
	location,
	...props
}) => (
	<React.Fragment>
		<Helmet defaultTitle="Data Plus" />
		<Helmet>
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
			/>
		</Helmet>
		{children}
	</React.Fragment>
);

const mapStateToProps = ({
	isTakeoverOpen,
}) => ({
	isScrollDisabled: (
		isTakeoverOpen
	)
});

export default connectToStore(mapStateToProps)(Layout);